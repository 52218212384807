.container {
  position: relative;
}

.container,
#interactive.viewport {
  width: 100%;
  height: 100%;
}

.container .button-close-scanner {
  position: absolute;
  top: 1rem;
  right: 1.4rem;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;

  color: #333;

  z-index: 40;
}

#interactive.viewport canvas,
video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 100%;
  height: 100%;
}
